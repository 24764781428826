import { useCart } from '@/store/cart';
import { useAuth } from '@/store/auth';

export default defineNuxtRouteMiddleware((_, from) => {
  const cart = useCart();
  const auth = useAuth();
  
  if (!(cart.account.userMail || auth.isLoggedIn)) {
    return navigateTo('/cart');
  }
});
